<template>
  <div id="bg">
    <div v-if="loaded" v-html="content" />
  </div>
</template>

<script>
export default {
  data: () => ({
    loaded: false,
  }),
  computed: {
    lesson() {
      return this.$store.getters['lesson/current'];
    },
    content() {
      // return 'weeee';
      return this.lesson.data.post[this.courseCurrentLanguage];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  watch: {},
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      await this.$store.dispatch('course/get', this.$route.params.courseid);

      if (!this.$store.getters['course/isLinear']) {
        await this.$store.dispatch(
          'module/select',
          this.$route.params.moduleid
        );
      }

      await this.$store.dispatch('lesson/select', this.$route.params.lessonid);

      this.loaded = true;

      this.$nextTick(() => {
        window.parent.resizeIframe();
      });
    },
  },
};
</script>

<style lang="scss">
* {
  color: #bac2c8;
}
#bg {
  background-color: #04122c;
}
</style>
